import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import NotFound from '@Pages/NotFound';
import PostContent from '@Modules/PostContent';
import Siderail from '@Modules/Siderail';
import ErrorBoundary from '@Core/ErrorBoundary';
import {getPostBySlug, updateMeta, getPageByID} from '@Actions';
import {cleanDate, stripHTML, decodeHTMLEntity} from '@Utils/misc';
import Grid from '@material-ui/core/Grid';

class Post extends Component{
    state = {
        id: 0,
        title: '',
        author: '',
        date: '',
        content: null,
        payload: {}
    }

    updateMeta = () => {
        let meta = {};
        let {api} = this.props;
        let p = api.activePost;

        meta['title'] = this.state.title || p.title.rendered;
        meta['ogTitle'] = this.state.title || p.title.rendered;
        meta['description'] = decodeHTMLEntity(stripHTML(p.excerpt.rendered));
        meta['ogDescription'] = decodeHTMLEntity(stripHTML(p.excerpt.rendered));
        meta['keywords'] = Object.keys(api.categories).join(',');
        meta['ogURL'] = window.location.href;
        meta['ogImage'] = p.featured_image;
        this.props.updateMeta(meta);
    }

    componentDidUpdate(prevProps, prevState){
        let {api} = this.props;
        if(prevProps.api.activePost !== api.activePost){
            if(api.activePost.type === 'page'){
                this.setState({...this.state,
                    id: api.activePost.id,
                    title: api.activePost.title.rendered,
                    author: api.activePost.author_name,
                    date: cleanDate(api.activePost.date),
                    content: api.activePost.content.rendered,
                    metadata: api.activePost.metadata
                }, () => {this.updateMeta()})
                return;
            }
            if(api.activePost.metadata.dynamic_rewards_default_content_use_dynamic_content) {
                maybeDynamicContent(api.activePost.content.rendered, api.activePost.metadata, api.activePost.featured_image).then(
                    data => this.setState({...this.state,
                        id: api.activePost.id,
                        title: maybeDynamicTitle(api.activePost.title.rendered, api.activePost.metadata),
                        author: api.activePost.author_name,
                        date: cleanDate(api.activePost.date),
                        content: data,
                        metadata: api.activePost.metadata
                    }, () => {this.updateMeta()})
                );
            } else {
                this.setState({...this.state,
                    id: api.activePost.id,
                    title: maybeDynamicTitle(api.activePost.title.rendered, api.activePost.metadata),
                    author: api.activePost.author_name,
                    date: cleanDate(api.activePost.date),
                    content: api.activePost.content.rendered,
                    metadata: api.activePost.metadata
                }, () => {this.updateMeta()})
            }
        }
    }

    componentDidMount(){
        let {location, isPage} = this.props;
        if(isPage){
            this.props.getPageByID(this.props.slug);
        }
        else{
            let paths = location.pathname.split('/');
            let slug = paths[paths.length - 1] || paths[paths.length - 2] || ''; // Trailing slashes cause slug to be ''
            this.props.getPostBySlug(slug);
        }
    }

    render(){
        return(
            <ErrorBoundary errorContent={<NotFound />}>
                <Grid container spacing={3}>
                    <PostContent
                        postID={this.state.id}
                        title={this.state.title}
                        author={this.state.author}
                        date={this.state.date}
                        content={this.state.content}
                        showTooltip={(this.props.isPage) ? false : true}
                    />
                    <Siderail postID={this.state.id} />
                </Grid>
            </ErrorBoundary>
        );
    }

}

/**
 * Functionality to replace instances of dynamic content tags
 * inside of article titles with their provided values.
 * 
 * This function replaces:
 * - [rewardamount] with a value provided from &rewardamount or the value provided in `dynamic_rewards_default_content_amount`
 * - [rewardtext] with a value provided from &rewardtext or the value provided in `dynamic_rewards_default_content_text`
 * 
 * @param str title The unmodified post title.
 * @param arr metadata The metadata array for the post.
 * 
 * @return str title The (maybe) modified/updated post title.
 */
const maybeDynamicTitle = (title, metadata) => {

    if(metadata.dynamic_rewards_default_content_use_dynamic_content){

        const _URL = new URL(window.location.href);

        const rewardAmount = _URL.searchParams.get('rewardamount') ? _URL.searchParams.get('rewardamount') : metadata.dynamic_rewards_default_content_amount;
        const rewardText = _URL.searchParams.get('rewardtext') ? _URL.searchParams.get('rewardtext') : metadata.dynamic_rewards_default_content_text;

        title = title.replaceAll('[rewardamount]', rewardAmount);
        title = title.replaceAll('[rewardtext]', rewardText);

    }

    return title;

}

/**
 * Functionality to replace instances of dynamic content tags
 * inside of article content with their provided values.
 * 
 * This function replaces:
 * - [rewardamount] with a value provided from &rewardamount or the value provided in `dynamic_rewards_default_content_amount`
 * - [rewardtext] with a value provided from &rewardtext or the value provided in `dynamic_rewards_default_content_text`
 * - The thumbnail image in the article content based on if &rewardimg includes a valid image attachment ID.
 * 
 * @param str content The unmodified post content.
 * @param arr metadata The metadata array for the post.
 * 
 * @return str content The (maybe) modified/updated post content.
 */
async function maybeDynamicContent(content, metadata, featured_image) {

    if(metadata.dynamic_rewards_default_content_use_dynamic_content){
        
        const _URL = new URL(window.location.href);

        const rewardAmount = _URL.searchParams.get('rewardamount') ? _URL.searchParams.get('rewardamount') : metadata.dynamic_rewards_default_content_amount;
        const rewardText = _URL.searchParams.get('rewardtext') ? _URL.searchParams.get('rewardtext') : metadata.dynamic_rewards_default_content_text;
        const rewardImage = _URL.searchParams.get('rewardimg');

        content = content.replaceAll('[rewardamount]', rewardAmount);
        content = content.replaceAll('[rewardtext]', rewardText);

        // if a reward image value exists and is a numeric value
        // lets try and actually replace the featured image on the post
        if(rewardImage && !isNaN(rewardImage)) {

            // hide the image while we attempt to swap it
            // by giving it a 'hidden' class which will be removed later
            content = content.replaceAll('lazyload', 'lazyload hidden');

            // send our new image id so we can try to swap it
            maybeDynamicImage(rewardImage);

        }

    }

    return content;

}

/**
 * Functionality to grab image data from the main TSW site from a provided 
 * numeric image ID.
 * 
 * @param int image The ID of the image to grab data for.
 * 
 * @return obj response.json() The JSON object of the fetch response.
 */
async function getImageURLByID(image) {

    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' },
    };

    const response = await fetch('https://thesmartwallet.com/wp-json/wp/v2/media/'+image, requestOptions);
    return response.json(); // parses JSON response into native JavaScript objects

}

/**
 * Functionality to maybe replace the featured image inside of a post
 * depending on whether or not we are able to get data back from the
 * provided image ID.
 * 
 * @param int new_thumbnail_id The ID of the thumbnail to be used.
 */
async function maybeDynamicImage(new_thumbnail_id){

    // hook into the executeQueryStringsFinished event and only fire this
    // when that event has finished
    window.addEventListener('executeQueryStringsFinished', async function(){

        try {

            // attempt to grab the url of the provided image id and swap it in the content
            let image_url_response = await getImageURLByID(new_thumbnail_id);

            // make sure there's an actual image response
            // if response.data exists, that means there's an error message
            if(!image_url_response['data']) {

                let new_thumbnail_url = image_url_response['source_url'];

                // swap `src` and `srcset` attributes with new thumbnail url
                document.getElementsByClassName('lazyload')[0].src = new_thumbnail_url;
                document.getElementsByClassName('lazyload')[0].setAttribute('srcset', new_thumbnail_url);

            }

        } catch(error) {

            console.log('Error getting custom reward image. Error: '+error.message);

        }

        // remove the hidden class from the featured image, regardless of
        // whether or not we actually replaced it
        document.getElementsByClassName('lazyload')[0].classList.remove('hidden');

    });

}

const mapStateToProps = (state) => ({ api: state.api });

export default connect(mapStateToProps, {getPostBySlug, updateMeta, getPageByID})(withRouter(Post));